<template>
  <div id="home-Left">
    <!-- 新的页面 -->
    <div id="home-left-data1" class="box-shadow"></div>

    <div id="home-left-data3" class="box-shadow"></div>

    <div id="home-left-data2" class="box-shadow"></div>
  </div>
</template>
<script>
import elementResizeDetectorMaker from 'element-resize-detector'
import { ALL_APPS_MENU } from '@/store/mutation-types'
import Vue from 'vue'

export default {
  name: 'TestBaiDu',
  data() {
    return {}
  },
  // 注册组件
  components: {},

  created() {},
  mounted() {
    // const all_app_menu = Vue.ls.get(ALL_APPS_MENU)[0].menu
    // this.allMenus = all_app_menu
    this.init()
  },
  methods: {
    async init() {},

    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        if (rowIndex % 2 === 0) {
          return {
            rowspan: 2,
            colspan: 1
          }
        } else {
          return {
            rowspan: 0,
            colspan: 0
          }
        }
      }
    },
    // 绘制图表
    drawEchart1() {
      let data = [
        { name: '1月', value: 400 },
        { name: '2月', value: 600 },
        { name: '3月', value: 800 },
        { name: '4月', value: 540 },
        { name: '5月', value: 720 },
        { name: '6月', value: 200 },
        { name: '7月', value: 210 },
        { name: '8月', value: 550 },
        { name: '9月', value: 900 },
        { name: '10月', value: 439 },
        { name: '11月', value: 670 },
        { name: '12月', value: 520 }
      ]
      if (this.chart1) {
        this.chart1.clear()
      } else {
        this.chart1 = this.$echarts.init(document.getElementById('echarts1'))
      }
      const erd = elementResizeDetectorMaker()
      erd.listenTo(document.getElementById('home-left-data2'), () => {
        this.chart1.resize()
      })
      let option = {
        grid: {
          right: 10
          // bottom: "7%",
        },
        tooltip: {
          trigger: 'axis'
        },
        xAxis: {
          type: 'category',
          axisTick: {
            alignWithLabel: true
          },
          data: data.map(x => {
            return x.name
          })
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            textStyle: {
              color: '#666'
            }
          },
          nameTextStyle: {
            color: '#666',
            fontStyle: 12
          },
          splitLine: {
            lineStyle: {
              type: 'dashed',
              color: '#818C98'
            }
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          }
        },
        series: [
          {
            name: '收入统计',
            type: 'line',
            stack: 'Total',
            smooth: true,
            symbol: 'emptyCircle',
            symbolSize: 7,
            lineStyle: {
              width: 2,
              color: '#0E9CFF'
            },
            itemStyle: {
              color: '#0E9CFF', //拐点颜色
              // borderColor: "#0000ff", //拐点边框颜色
              borderWidth: 2 //拐点边框大小
            },
            areaStyle: {
              opacity: 0.12,
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#0E9CFF'
                },
                {
                  offset: 1,
                  color: '#ffffff'
                }
              ])
            },
            emphasis: {
              focus: 'series'
            },
            data: data
          }
        ]
      }

      option && this.chart1.setOption(option)
    },
    drawEchart2() {
      if (this.chart2) {
        this.chart2.clear()
      } else {
        this.chart2 = this.$echarts.init(document.getElementById('echarts2'))
      }
      const erd = elementResizeDetectorMaker()
      erd.listenTo(document.getElementById('home-left-data3'), () => {
        this.chart2.resize()
      })
      let option = {
        title: {
          text: 'Referer of a Website',
          subtext: 'Fake Data',
          left: 'center'
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          left: 'left'
        },
        series: [
          {
            name: 'Access From',
            type: 'pie',
            radius: '50%',
            data: [
              { value: 1048, name: 'Search Engine' },
              { value: 735, name: 'Direct' },
              { value: 580, name: 'Email' },
              { value: 484, name: 'Union Ads' },
              { value: 300, name: 'Video Ads' }
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      }

      option && this.chart2.setOption(option)
    }
  }
}
</script>
<style lang="scss" scoped>
// ::v-deep .table-header-background {
//   background-color: #f7d08a;
// }
#home-Left {
  width: 100%;
  padding-right: 20px;

  #home-left-data1,
  #home-left-data2,
  #home-left-data3 {
    width: 100%;
    padding: 0.125rem;
    margin-bottom: 0.21rem;
    background: #ffffff;
    border-radius: 0.05rem;
    opacity: 1;
    position: relative;
  }

  #home-left-data1 {
    height: 2.925rem;
    background: linear-gradient(112deg, #2473ff 0%, #5c99ff 100%);
  }

  #home-left-data2 {
    height: 4.375rem;
  }

  #home-left-data3 {
    height: 2.6rem;
  }
}

.box-shadow:hover {
  box-shadow: 0 0 11px 0 rgba(0, 0, 0, 0.15);
}
</style>
